import ExpCard from "../ExpCard";
import { experiences } from "../../libs/experiences";

export default function Experience() {
  return (
    <div id="experience" className="exp-radial-background">
      <div className="h-full backdrop-blur-3xl bg-white/30 flex flex-col items-center justify-center gap-10 md:gap-5 py-10 px-3 md:px-5 lg:px-10">
        {/* section name */}
        <div className="w-full flex items-center justify-start gap-5 ">
          <h4 className="poppins-style font-medium text-xl">Experience</h4>
          <div className="w-[200px] h-[2px] bg-gradient-to-r from-[#725ba6] to-transparent rounded-full"></div>
        </div>
        <div className="w-full flex flex-wrap gap-5">
          {experiences.map((experience, index) => {
            return (
              <ExpCard
                title={experience.title}
                icon={experience.icon}
                subTitle={experience.subTitle}
                desc={experience.desc}
                key={index}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
