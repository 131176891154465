import SwitchBtn from "../SwitchBtn";
import ProjectCard from "../ProjectCard";
import { useState } from "react";
import { projects } from "../../libs/projects";

export default function Projects() {
  const [activeBtn, setActiveBtn] = useState(0);
  const [filteredProjects, setFilteredProjects] = useState(projects);

  const onClickHandler = (btnId) => {
    setActiveBtn(btnId);
    
    if(btnId === 0){
      setFilteredProjects(projects)

    }else{
      const filtered = projects.filter(project => project.typeOfProject.includes(btnId));
      setFilteredProjects(filtered);
    }
    
    
  };

  

  return (
    <div id="projects" className="skill-radial-background">
      <div className="h-full backdrop-blur-3xl bg-white/30 flex flex-col items-start justify-start gap-5 md:gap-5 py-10 px-3 md:px-5 lg:px-10">
        {/* section name */}
        <div className="w-full flex items-center justify-start gap-5 ">
          <h4 className="poppins-style font-medium text-xl">Projects</h4>
          <div className="w-[200px] h-[2px] bg-gradient-to-r from-[#725ba6] to-transparent rounded-full"></div>
        </div>
        {/* switch btn - filtering  */}
        <SwitchBtn handleSwitching={onClickHandler} activeBtn={activeBtn} />

        {/* projects */}
        <div className=" w-full h-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 ps-[5%] sm:ps-0">
          {filteredProjects.map((project, index) => {
            // Call filterProjects as a function
            return <ProjectCard project={project} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
}
