import { ReactComponent as University } from "../assets/university.svg";
import { ReactComponent as Group } from "../assets/group.svg";
import { ReactComponent as Course } from "../assets/course.svg";

export const experiences =[
    {
        title:"Software Engineering Department",
        icon:<University/>,
        subTitle:"Koya Uiversity",
        desc:"2020 - 2024"
    },
   
    {
        title:"Web Design & Development Bootcamp ",
        icon:<Course/>,
        subTitle:"weCode Bootcamp - Rwanga Foundation",
        desc:"5th bootcamp - 2022"
    },
    // {
    //     title:"SoftSpace Club ",
    //     icon:<Group/>,
    //     subTitle:"Co-Founder",
    //     desc:"2023 - present"
    // },
]