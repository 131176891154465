import { ReactComponent as User } from "../assets/user.svg";
import { ReactComponent as Email } from "../assets/email.svg";

export default function Form() {
  return (
    <div className="w-full sm:w-[80%] md:w-1/2 py-3 lg:px-14 flex items-center justify-center ">
      <form className="w-full border p-5 rounded-3xl">
        <div className="mb-5">
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
              <User />
            </div>
            <input
              type="text"
              id="name"
              className="bg-gray-50 border  text-gray-900 text-sm rounded-lg  focus:outline-none  block w-full ps-10 p-2.5 "
              placeholder="Full Name"
            />
          </div>
        </div>
        <div className="mb-5">
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
              <Email />
            </div>
            <input
              type="text"
              id="email"
              className="bg-gray-50 border  text-gray-900 text-sm rounded-lg  focus:outline-none  block w-full ps-10 p-2.5 "
              placeholder="Email@gmail.com"
            />
          </div>
        </div>
        <div className="mb-5">
          <textarea
            id="message"
            rows="4"
            class="block p-2.5 w-full text-sm text-gray-900 focus:outline-none  bg-gray-50 rounded-lg border "
            placeholder="Leave a message..."
          ></textarea>
        </div>

        <button
          type="submit"
          className="w-full text-[#fff] bg-violet-400  hover:bg-violet-300  font-medium rounded-lg text-lg px-5 py-2.5 text-center"
        >
          Send
        </button>
      </form>
    </div>
  );
}
