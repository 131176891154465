
const btns = [
  {
    id: 0,
    label: "All Projects",
  },
  {
    id: 1,
    label: "UI Projects",
  },
  {
    id: 2,
    label: "Web Projects",
  },
];

export default function SwitchBtn({handleSwitching, activeBtn}) {


  return (
    <div className="p-1 bg-[#b2b2b228] rounded-full flex items-center justify-center">
      {btns.map((btn,index) => {
        return (
          <button
          key={index}
            className={`py-1 md:py-2 px-3 md:px-5 rounded-full text-xs sm:text-sm md:text-md poppins-style font-medium ${
              activeBtn === btn.id
                ? "bg-white text-black"
                : "text-gray-400 bg-transparent"
            }`}
            onClick={() => handleSwitching(btn.id)}
          >
            {btn.label}
          </button>
        );
      })}
    </div>
  );
}
