import mobile from "../../assets/mobile.png";
import SkillSet from "../SkillSet";

export default function About() {
  return (
    <div id="about" className="home-radial-background ">
      <div className="h-full backdrop-blur-3xl bg-white/30 flex flex-col md:flex-row items-center justify-center gap-10 md:gap-0 py-10 px-3 md:px-5 lg:px-10">
        <div className="left-container h-full flex flex-col items-end justify-center gap-5">
          {/* section name */}
          <div className="w-full flex items-center justify-start gap-5 ">
            <h4 className="poppins-style font-medium text-xl">About</h4>
            <div className="w-[200px] h-[2px] bg-gradient-to-r from-[#725ba6] to-transparent rounded-full"></div>
          </div>

          {/* section content */}
          <div className="text-black poppins-style text-sm flex flex-col items-start justify-start gap-8">
            <p>
              I am <span className="text-[#494D9D] font-medium">Sanarya</span> ,
              Software Engineering student at Koya University. My love for logic
              translates into strong front-end skills.
              I build functional and interactive web applications, ensuring
              smooth operation and performance
            </p>
            <p>
              Beyond code, I have a keen eye for design. I translate abstract
              user experience (UX) concepts into clean and intuitive user
              interfaces (UI), prioritizing a user-friendly experience.
            </p>
            <p>
              Collaboration is key. My knowledge of established software
              development methodologies allows me to thrive in teams, tackling
              problems effectively and delivering high-quality solutions.
            </p>
            <p>
              Check out my portfolio and collaborate on something awesome! 🌚🤞
            </p>
          </div>
        </div>

        {/* right side which is img for lg screen and below lg->img->hidden  */}
        <div className="right-container w-[40%] h-full ">
          <SkillSet display="hidden" />
          <img src={mobile} className="w-[380px]  " alt="mobile" />
        </div>

        <SkillSet display="flex" />
      </div>
    </div>
  );
}
