import budgetmanagement from "../assets/budgetManagement.png"
import drOnline from "../assets/drOnlineApp.png"
import rozbeTravel from "../assets/rozbeTravel.png"
import portfolio from "../assets/portfolio.png"
import reado from "../assets/reado.png"
import daroonDashboard from "../assets/daroon dashboard.png"
import daroonApp from "../assets/daroon app.png"
import EPU from "../assets/EPU.png"

export const projects = [
    {
        id:1,
        src:budgetmanagement,
        typeOfProject:"2",
        gitHubRepo:"",
        hostLink:"https://manageyourbudget.000webhostapp.com"
    },
    {
        id:2,
        src:drOnline,
        typeOfProject:"1",
    },
    {
        id:3,
        src:rozbeTravel,
        typeOfProject:"2",
        gitHubRepo:"",
        hostLink:"#"
    },
    {
        id:4,
        src:portfolio,
        typeOfProject:"2",
        gitHubRepo:"",
        hostLink:"https://master--sanaryahanakarim.netlify.app/"
    },
    {
        id:5,
        src:daroonDashboard,
        typeOfProject:"1",
    },
    {
        id:6,
        src:daroonApp,
        typeOfProject:"1",
    },
    {
        id:7,
        src:EPU,
        typeOfProject:"1",
    },
]