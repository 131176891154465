import { ReactComponent as Host } from "../assets/host.svg";

export default function ProjectCard({ project }) {
  return (
    <div className="w-[95%] sm:w-full bg-transparent hover:border rounded-2xl">
      <div className="w-full flex flex-col items-center justify-center">
        <img src={project.src} alt="" width="100%" />
        <div className="w-full h-full bg-gradient-to-t from-white to- relative">
          <div className="flex items-center justify-between w-full px-3 absolute bottom-[10px]">
            <h6 className="px-3 py-1 poppins-style  rounded-[5px] text-[#725ba6] text-sm font-bold">
              {project.typeOfProject === "1" ? "UI Project" : "Web Project"}
            </h6>
            <div className="flex items-center justify-center gap-1">
              {project.typeOfProject !== "1" ? (
                ""
                // <a href={project.hostLink}>
                //   <Host />
                // </a>
              ) : (
                ""
              )}
              {/* <SeeAll /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
