export const sections =[
    {
        id:1,
        path:"#home",
        label:"Home"
    },
    {
        id:2,
        path:"#about",
        label:"About"
    },
    {
        id:3,
        path:"#skills",
        label:"Skills"
    },
    {
        id:4,
        path:"#experience",
        label:"Experience"
    },
    {
        id:5,
        path:"#projects",
        label:"Projects"
    },
    {
        id:6,
        path:"#contact",
        label:"Contact"
    },
]