import { ReactComponent as AntDesign } from "../assets/antdesign.svg";
import { ReactComponent as Bootstrap } from "../assets/bootstrap.svg";
import { ReactComponent as Css } from "../assets/css.svg";
import { ReactComponent as DrawIo } from "../assets/drawio.svg";
import { ReactComponent as Figma } from "../assets/figma.svg";
import { ReactComponent as Flowbite } from "../assets/flowbite.svg";
import { ReactComponent as GitHub } from "../assets/git_hub.svg";
import { ReactComponent as Git } from "../assets/git.svg";
import { ReactComponent as Mysql } from "../assets/mysql.svg";
import { ReactComponent as Html } from "../assets/html.svg";
import { ReactComponent as Js } from "../assets/javascript.svg";
import { ReactComponent as LucidChart } from "../assets/lucid.svg";
import { ReactComponent as Mui } from "../assets/mui.svg";
import { ReactComponent as NextJs } from "../assets/nextjs.svg";
import { ReactComponent as NextUi } from "../assets/nextui.svg";
import { ReactComponent as NodeJs } from "../assets/nodejs.svg";
import { ReactComponent as Npm } from "../assets/npm.svg";
import { ReactComponent as Php } from "../assets/php.svg";
import { ReactComponent as Postman } from "../assets/postman.svg";
import { ReactComponent as React } from "../assets/react.svg";
import { ReactComponent as Redux } from "../assets/redux.svg";
import { ReactComponent as Sequelize } from "../assets/sequelize.svg";
import { ReactComponent as Sql } from "../assets/sql.svg";
import { ReactComponent as Tailwind } from "../assets/tailwindcss.svg";
import { ReactComponent as VsCond } from "../assets/vscode.svg";
import { ReactComponent as Miro } from "../assets/miro.svg";

export const skills = [
  {
    name: "CSS",
    icon: <Css />,
    bg: "#1572B6",
  },
  {
    name: "HTML",
    icon: <Html />,
    bg: "#E44F26",
  },
  {
    name: "JS",
    icon: <Js />,
    bg: "#F7DF1E",
  },
  {
    name: "React.js",
    icon: <React />,
    bg: "#00D8FF",
  },
  {
    name: "Redux",
    icon: <Redux />,
    bg: "#764ABC",
  },
  {
    name: "Bootstrap",
    icon: <Bootstrap />,
    bg: "#7A12F8",
  },
  {
    name: "Tailwind",
    icon: <Tailwind />,
    bg: "#191C67",
  },
  {
    name: "Ant Design",
    icon: <AntDesign />,
    bg: "#F9696B",
  },
  {
    name: "Flowbite",
    icon: <Flowbite />,
    bg: "#2379BD",
  },
  {
    name: "MUI",
    icon: <Mui />,
    bg: "#03AFEF",
  },
  {
    name: "NextUI",
    icon: <NextUi />,
    bg: "#fff",
  },
  {
    name: "Next.js",
    icon: <NextJs />,
    bg: "#000",
  },
  {
    name: "Node.js",
    icon: <NodeJs />,
    bg: "#6CC04A",
  },
  {
    name: "MySQL",
    icon: <Mysql />,
    bg: "#FFB039",
  },
  {
    name: "Sequelize.js",
    icon: <Sequelize />,
    bg: "#2379BD",
  },
  {
    name: "SQL",
    icon: <Sql />,
    bg: "#FFDA44",
  },
  {
    name: "PHP",
    icon: <Php />,
    bg: "#6181B6",
  },
  {
    name: "npm",
    icon: <Npm />,
    bg: "#C12127",
  },
  {
    name: "Github",
    icon: <GitHub />,
    bg: "#000",
  },
  {
    name: "Git",
    icon: <Git />,
    bg: "#F34F29",
  },

  {
    name: "Figma",
    icon: <Figma />,
    bg: "#000",
  },

  {
    name: "Lucid Chart",
    icon: <LucidChart />,
    bg: "#fff",
  },
  {
    name: "Draw.io",
    icon: <DrawIo />,
    bg: "#F08705",
  },

  {
    name: "Postman",
    icon: <Postman />,
    bg: "#F37036",
  },

  {
    name: "VS Code",
    icon: <VsCond />,
    bg: "#41ABF2",
  },
  {
    name: "Miro",
    icon: <Miro />,
    bg: "#FFD02F",
  },
];
