import { ReactComponent as Code } from "../assets/code.svg";
import { ReactComponent as Diagram } from "../assets/diagram.svg";
import { ReactComponent as UI } from "../assets/ui.svg";

export default function SkillSet( {display}) {
  return (
    <div className={` w-full ${display === "flex" ? "flex  md:hidden items-start justify-start gap-5 md:gap-0" : "hidden md:block"}`}>
      {/* web dev */}
      <div className="py-2 px-2 rounded-3xl md:absolute right-[20%] top-[210px] backdrop-blur-3xl bg-white/30 ">
        <div className="flex items-center justify-start gap-2">
          <Code />
          <p className="poppins-style text-xs  md:text-sm font-medium">Web Development</p>
        </div>
      </div>
      {/* analyzing */}
      <div className="py-2 px-2 rounded-3xl md:absolute right-[10%] top-[350px] backdrop-blur-3xl bg-white/30 ">
        <div className="flex items-center justify-start gap-2">
          <Diagram />
          <p className="poppins-style text-xs md:text-sm font-medium">
            Analyze & Planning
          </p>
        </div>
      </div>
      {/* ui and ux */}
      <div className="py-2 px-2 rounded-3xl md:absolute right-[5%] top-[70px] backdrop-blur-3xl bg-white/30 ">
        <div className="flex items-center justify-start gap-2">
          <UI />
          <p className="poppins-style text-xs md:text-sm font-medium">UI & UX Design</p>
        </div>
      </div>
    </div>
  );
}
