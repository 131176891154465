import Form from "../Form";
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

export default function Contact() {
  return (
    <div id="contact" className="exp-radial-background">
      <div className="h-full backdrop-blur-3xl bg-white/30 flex flex-col items-center justify-center gap-10 md:gap-5 py-10 px-3 md:px-5 lg:px-10">
        {/* section name */}
        <div className="w-full flex items-center justify-start gap-5 ">
          <div className="w-[500px] h-[2px] bg-gradient-to-r from-[#725ba6] to-transparent rounded-full"></div>
        </div>
        <div className="w-full flex flex-col md:flex-row gap-5 ">
          <div className="w-full md:w-[50%] flex items-start justify-start md:flex-col md:items-center md:justify-around ">
            <div className="w-full  flex flex-col gap-3 ">
              <h6 className="poppins-style font-medium text-3xl">
                Get In Touch!
              </h6>
              <p className="poppins-style text-sm text-[#000000c6] w-full sm:w-[70%] md:w-full">
                Have a project in mind? Drop me a line! I'm always interested in
                connecting and exploring new opportunities.
              </p>
              <div className="flex items-center justify-start gap-2 ">
              <a href="https://github.com/sanaryaa">
                <FaGithub className="text-[26px] text-[#4c4c4c] transition duration-500 ease-in-out hover:text-[#252525]" />
              </a>
              <a href="https://www.linkedin.com/in/sanarya-hamakarim">
                <FaLinkedin className="text-[26px] text-[#4c4c4c] transition duration-500 ease-in-out hover:text-[#252525] " />
              </a>
              </div>
            </div>
            <div className="hidden md:flex w-full  justify-end gap-5">
          <div className="w-[60%] h-[2px] bg-gradient-to-r from-transparent to-[#5ba5a6] rounded-full"></div>
        </div>
          </div>
          <Form />
        </div>
      </div>
    </div>
  );
}
