import { NavLink } from "react-router-dom";
import { useState } from "react";
import { sections } from "../libs/sections";
import { ReactComponent as Menu } from "../assets/menu.svg";
import { ReactComponent as Close } from "../assets/close.svg";

export default function Nav() {
  const [isOpen, setOpen] = useState(false);
  const [activeLink,setLinkActive]=useState(1);

  //to handle click on the menu button
  const onClickHandler = () => {
    setOpen(!isOpen);
  };

  //to give different style for active link
  const setActiveLink = (linkId) => {
    setLinkActive(linkId)
    setOpen(!isOpen);
  };

  return (
    <nav className="fixed top-0 w-full z-10 backdrop-blur-3xl bg-white/30 ">
      <div className="max-w-screen-xl flex flex-wrap md:flex-nowrap items-center justify-between mx-auto p-4">
        <NavLink
          to="/"
          className="space-grotesk-bold logo text-xl  md:text-2xl flex items-center space-x-3 rtl:space-x-reverse"
        >
          Sanarya
        </NavLink>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-14 h-14 justify-center text-sm text-gray-500 rounded-lg md:hidden focus:outline-none "
          aria-controls="navbar-default"
          aria-expanded="false"
          onClick={onClickHandler}
        >
          <span className="sr-only">Open main menu</span>
          {
            isOpen? <Close/>:<Menu/>
          }
         
        </button>
        <div
          className={`${
            isOpen ? "block" : "hidden"
          } md:block w-full md:w-auto flex "`}
        >
          <ul className="w-full bg-[#f2f2f26e] md:bg-transparent font-medium flex flex-col p-4 md:p-0 mt-4 rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 ">
            {sections.map((section, index) => {
              return (
                <li key={index} onClick={() => setActiveLink(section.id)} className="w-full  text-center">
                  <a
                  
                    href={section.path}
                    className={`poppins-style w-full text-center text-sm block py-2 px-3 rounded md:bg-transparent  ${activeLink === section.id? "text-[#000] text-md ": "text-gray-400 hover:text-gray-500"}`}
                    aria-current="page"
                  >
                    {section.label}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
}
