export default function Footer() {
  return (
    <footer class="bg-white rounded-lg ">
      <div class="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
        <span class="text-xs text-gray-500 sm:text-center ">
          © 2024 
          <a href="#home" class="hover:underline">
             Sanarya Hamakarim
          </a>
          . All Rights Reserved.
        </span>
       
      </div>
    </footer>
  );
}
