export default function ExpCard({ title , icon , subTitle , desc }) {

  return (
    <div className="p-5 rounded-full border flex gap-4 items-center justify-start">
      <div className="p-3 bg-[#D8E3F7] rounded-full w-[70px] h-[70px] flex items-center justify-center">
        {icon}
      </div>
      <div className="flex flex-col items-start justify-center gap-2">
        <h6 className="poppins-style font-medium text-sm md:text-md">
            {title}
        </h6>
        <div className="flex flex-col items-start justify-center gap-2">
        <p className="font-medium poppins-style text-gray-600 text-xs md:text-sm">{subTitle}</p>
        <p className="font-regular poppins-style text-gray-600 text-[10px] md:text-xs">{desc}</p>
        </div>
       
      </div>
    </div>
  );
}
