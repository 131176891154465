import Footer from "../components/Footer";
import Nav from "../components/Nav";
import About from "../components/sections/About";
import Contact from "../components/sections/Contact";
import Experience from "../components/sections/Experience";
import Home from "../components/sections/Home";
import Projects from "../components/sections/Projects";
import Skills from "../components/sections/Skills";

export default function Portfolio() {
  return (
    <div className="w-full">
      <div className="w-full">
        <Nav />
      </div>

      <div className="w-full">
        <Home />
        <About />
        <Skills />
        <Experience />
        <Projects />
        <Contact />
      </div>
      <Footer />
    </div>
  );
}
