import profile from "../../assets/profile.png";
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import myCV from "../../pdf/sanaryaCV.pdf";
import { HiOutlineDownload } from "react-icons/hi";

export default function Home() {
  return (
    <div id="home" className="about-radial-background h-[500px] mt-[70px]">
      <div className=" h-full backdrop-blur-2xl bg-white/30 flex flex-col items-center justify-center gap-16">
        <div className=" flex items-center justify-center flex-col">
          <img
            src={profile}
            className="w-[150px] sm:w-[180px]"
            alt="character"
          />
          <div className="flex flex-col items-center justify-center gap-4">
            <div className="flex flex-col items-center justify-center gap-1">
              <h5 className="poppins-style text-center text-md sm:text-lg md:text-2xl">
                Sanarya Hamakarim
              </h5>
              <p className="poppins-style text-gray-600 text-center text-sm sm:text-md md:text-lg">
                I'm Problem Solver
              </p>
            </div>

            <div className="flex items-center justify-center gap-2">
              <a href="https://github.com/sanaryaa">
                <FaGithub className="text-[26px] text-[#4c4c4c] transition duration-500 ease-in-out hover:text-[#252525]" />
              </a>
              <a href="https://www.linkedin.com/in/sanarya-hamakarim">
                <FaLinkedin className="text-[26px] text-[#4c4c4c] transition duration-500 ease-in-out hover:text-[#252525] " />
              </a>
            </div>
            <a
              href={myCV}
              download
              className="poppins-style text-sm md:text-md text-white bg-gradient-to-r from-[#c4aed2cd] to-[#ca8eb1] px-2  md:px-5 py-1 rounded-xl transition duration-500 ease-in-out hover:bg-gradient-to-r hover:from-[#bfa0d2dd] hover:to-[#ca81ac]  "
            >
              <button className="flex items-center justify-between gap-2">
                My Resume <HiOutlineDownload className="text-white text-xl" />
              </button>
            </a>
          </div>
        </div>
        <div className="py-2 px-10 flex items-center justify-center flex-col bg-[#ffffff83] rounded-md">
          <h6 className="poppins-style text-center text-xs sm:text-sm  ">
            "First, Solve the problem. then write the code."
          </h6>
          <p className="poppins-style text-center text-xs text-gray-500 ">
            -John Johnson
          </p>
        </div>
      </div>
    </div>
  );
}
